import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      //Page Tabs
      search: 'Search',
      cart: 'Cart',
      orders: 'Orders',
      dispatch: 'Dispatch',
      balance: 'Balance',
      login: 'Login',
      register: 'SignUp',
      logout: 'LogOut',
      howto: 'How To',
      offers: 'Offers',

      //footer
      terms_and_conditions: 'Terms and Conditions',
      contacts: 'Contacts',
      contact_us: 'Contact Us',
      home: 'Home',
      about_us: 'About Us',
      privacy_policy: 'Privacy Policy',
      follow_us_by: 'Follow us by',

      //header
      profile: 'Profile',

      // User data fields
      user_name: 'NAME',
      user_country: 'COUNTRY',
      user_zip: 'POST CODE',
      user_state: 'STATE/PROVINCE',
      user_city: 'CITY',
      user_street: 'STREET',
      user_building: 'BUILDING',
      user_flat: 'FLAT/OFFICE',
      user_phone: 'PHONE',
      user_email: 'EMAIL',
      user_tax: 'TAX NUMBER',
      user_currency: 'CURRENCY',
      user_contact_person: 'CONTACT PERSON',
      save: 'SAVE',

      // Search page
      by_part_number: 'By Part Number',
      by_excel_file: 'By Excel File',
      to_cart: 'To Cart',
      export: 'Export',
      upload: 'Upload',
      max_days: 'Max days',
      template: 'Template',
      demo_prices_log_in:
        'You see DEMO prices. To get actual ones, kindly login',
      demo_prices_complete_registration:
        'You see DEMO prices. To get actual ones, kindly deposit USD500 to complete registration',
      for_order: 'To Order',
      error_quotation: 'Cannot process quotation due to error',
      quotation_differs:
        'Some items you can purchase with new lead times, prices or quantities',

      // Balance page
      cannot_export_invoice: 'Cannot export invoice due to the error',
      start_date: 'Start Date',
      end_date: 'End Date',
      show: 'Show',
      topup_online: 'TopUp Online',
      bank_transfer: 'Bank Transfer',
      document: 'document',
      debit: 'Debit',
      credit: 'Credit',

      // Basket page
      cannot_select_item: 'Cannot select item(s) due to the error',
      cannot_change_quantity: 'Cannot change quantity due to the error',
      cannot_apply_changes: 'Cannot apply changes due to the error',
      cannot_order_selected: 'Cannot order selected due to the error',
      cannot_delete_selected: 'Cannot delete selected due to the error',
      order: 'Order',
      remove: 'Remove',
      order_accepted:
        'Your order № {{orderId}} in amount of {{currency}}{{amount}} is accepted.',
      order_not_accepted:
        'Your order not accepted due to the error (code = {{orderId}})',
      activate_account: 'Please contact us to activate your account',
      insufficient_funds: 'Insufficient funds, please check your balance',
      no_items: 'No items for order',
      basket_log_in:
        'To place orders, kindly login',
      basket_complete_registration:
        'To place orders, kindly deposit USD500 to complete registration',

      // Orders page
      part_number: 'Part number',
      order_number: 'Order number',
      reference: 'Reference',
      all: 'all',

      // Password reset page
      submit: 'Submit',
      new_password: 'NEW PASSWORD',

      // Shipments page
      cannot_update_shipment_date:
        'Cannot update shipment date due to the error',

      // Balance topup dialog
      amount_with_currency: 'AMOUNT ({{currency}})',
      charge: 'CHARGE: {{percent}}% + {{fixed}} {{currency}}',
      topup: 'TOP UP',

      // Balance total table
      balance_with_currency: 'Balance ({{currency}})',
      in_orders: 'In Orders',
      in_cart: 'In Cart',
      pay_for_cart: 'Pay for Cart',
      ready_to_ship: 'Ready to Ship',
      pay_to_ship: 'Pay to Ship',

      // Basket table
      brand: 'Brand',
      price_with_currency: 'Price ({{currency}})',
      quantity: 'Quantity',
      total_with_currency: 'Total ({{currency}})',
      weight_kg: 'Weight (kg)',
      booking: 'Booking',
      delivery: 'Delivery',
      description: 'Description',
      duplication: 'Duplication',

      // Basket total table
      to_order: 'To Order',
      available_for_order: 'Available for Order',
      pay_for_order: 'Pay for Order',
      items: 'Items',

      // Captcha
      captcha_field_placeholder: 'ENTER TEXT FROM AN IMAGE ABOVE',

      //Catalog page
      offers_log_in:
        'To download offers, kindly login',
      offers_complete_registration:
        'To download offers, kindly deposit USD500 to complete registration',

      // Catalog table
      offer: 'Offer',
      download: 'Download',
      lines: 'Lines',
      updated: 'Updated',

      //Email verified page
      email_verified: 'Your email has been verified',

      // Log In dialog
      email: 'email',
      password: 'password',
      forgot_password: 'Forgot password?',
      reset_link_sent: 'Reset link is sent to your email',
      log_in: 'LOG IN',

      // Orders table
      date: 'Date',
      order_price: 'Order Price',
      sale_price: 'Sale Price',
      ordered: 'Ordered',
      purchased: 'Purchased',
      shipped: 'Shipped',
      refused: 'Refused',
      state_date: 'State Date',

      // Search input
      search_input_placeholder: 'Search...',

      // Search table
      substituted: 'Substituted',
      days: 'Days',
      available: 'Available',
      volume_kg: 'Volume (kg)',
      comment: 'Comment',
      n_a: 'N/A',

      // Shipment date dialog
      enabled: 'Enabled',

      // Shipment box table
      row_id: 'Row ID',

      // Shipment table
      place: 'place',
      length_m: 'Length (m)',
      height_m: 'Height (m)',
      width_m: 'Width (m)',
      value_with_currency: 'Value ({{currency}})',

      // Shipment total table
      places: 'Places',
      volume_m3: 'Volume (㎥)',
      schedule: 'Schedule',
      choose_date: 'Choose date',

      // Sign up dialog
      company_name: 'company name',
      contact_phone: 'contact phone',
      confirm_password: 'confirm password',
      sign_up: 'sign up',
      error_password_not_match: 'Passwords do not match',

      //Manuals page
      tab_register: 'register',
      tab_quotations: 'make quotations',
      tab_offers: 'download offers',
      tab_api: 'use API',

      //TopUp Dialog
      error_amount: 'Incorrect amount',
      error_something: 'Something went wrong, contact us',
    },
  },
  ar: {
    translation: {
      //Page Tabs
      search: 'بحث',
      cart: 'عربة التسوق',
      orders: 'الطلب',
      dispatch: 'شحنة',
      balance: 'الرصيد',
      login: 'تسجيل دخول',
      register: 'مستخدم جديد',
      logout: 'خروج',
      offers: 'عروض',
      howto: 'كيف',

      //footer
      terms_and_conditions: 'Terms and Conditions',
      contacts: 'Contacts',
      contact_us: 'اتصل بنا',
      home: 'الصفحة',
      about_us: 'عنا',
      privacy_policy: 'سياسة الخصوصي',
      contact_number: '',
      follow_us_by: 'تابعونا على',

      //header
      profile: 'Profile',

      // User data fields
      user_name: 'NAME',
      user_country: 'COUNTRY',
      user_zip: 'POST CODE',
      user_state: 'STATE/PROVINCE',
      user_city: 'CITY',
      user_street: 'STREET',
      user_building: 'BUILDING',
      user_flat: 'FLAT/OFFICE',
      user_phone: 'PHONE',
      user_email: 'EMAIL',
      user_tax: 'TAX NUMBER',
      user_currency: 'CURRENCY',
      user_contact_person: 'CONTACT PERSON',
      save: 'SAVE',

      // TODO translate below
      // Search page
      by_part_number: 'حسب رقم القطعة',
      by_excel_file: '  excel بواسطة ملف ',
      to_cart: 'الى السلة',
      export: 'تصدير',
      upload: 'تحميل',
      max_days: 'العدد الاقصى للايام',
      template: 'نموذج',
      demo_prices_log_in:
        'انت ترى اسعارا تجريبية . للحصول على الاسعار الفعلية يرجى تسجيل الدخول',
      demo_prices_complete_registration:
        'انت ترى اسعارا تجريبية .للحصول على الاسعار الفعلية ، يرجى إكمال التسجيل والإتصال بنا على ',
      for_order: 'للطلب',
      error_quotation: 'Cannot process quotation due to error',
      quotation_differs:
        'Some items you can purchase with new lead times, prices or quantities',

      // Balance page
      cannot_export_invoice: 'لا يمكن إصدار الفاتورة بسبب الخطأ',
      start_date: 'تاريخ البدء',
      end_date: 'تاريخ الانتهاء',
      show: 'إظهار',
      topup_online: 'اشحن رصيدك عبر الإنترنت',
      bank_transfer: 'حوالة بنكية',
      document: 'وثيقة',
      debit: 'بطاقة مدين',
      credit: 'بطاقة دائن',

      // Basket page
      cannot_select_item: 'لا يمكن تحديد العنصر (العناصر) بسبب الخطأ',
      cannot_change_quantity: 'لا يمكن تغيير الكمية بسبب الخطأ',
      cannot_apply_changes: 'لا يمكن تطبيق التغييرات بسبب الخطأ',
      cannot_order_selected: 'للا يمكن طلب المحدد بسبب خطأ',
      cannot_delete_selected: 'لا يمكن حذف المحدد بسبب الخطأ',
      order: 'الطلب',
      remove: 'إزالة',
      order_accepted:
        'طلبك № {{orderId}} بمبلغ وقدره {{currency}}{{amount}} تم قبول.',
      order_not_accepted: 'لم يتم قبول الطلب بسبب خطآ (كود = {{orderId}})',
      activate_account: 'يرجى الاتصال بنا لتفعيل حسابك',
      insufficient_funds: 'أموال غير كافية ، يرجى التحقق من رصيدك',
      no_items: 'القطع المطلوبة غير متوفرة',
      basket_log_in:
        'To place orders, kindly login',
      basket_complete_registration:
        'To place orders, kindly deposit USD500 to complete registration',

      // Orders page
      part_number: 'رقم القطعة',
      order_number: 'رقم الطلب',
      reference: 'مرجع',
      all: 'لكل',

      // Password reset page
      submit: 'إرسال',
      new_password: 'كلمة سر جديدة',

      // Shipments page
      cannot_update_shipment_date: 'لا يمكن تحديث تاريخ الشحن بسبب الخطأ',

      // Balance topup dialog
      amount_with_currency: 'االمبلغ({{currency}})',
      charge: 'تكلفة اضافية: {{percent}}% + {{fixed}} {{currency}}',
      topup: 'تعبئة الرصيد',

      // Balance total table
      balance_with_currency: '({{currency}}) الرصيد',
      in_orders: 'تحت الطلب',
      in_cart: 'في العربة',
      pay_for_cart: 'الدفع للسلة',
      ready_to_ship: 'جاهزة للشحن',
      pay_to_ship: 'الدفع للشحن',

      // Basket table
      brand: 'العلامة التجارية',
      price_with_currency: '({{currency}}) السعر',
      quantity: 'الكمية',
      total_with_currency: '({{currency}}) المجموع',
      weight_kg: 'الوزن (ك غ)',
      booking: 'الحجز',
      delivery: 'التوصيل',
      description: 'الوصف',
      duplication: 'Duplication',

      // Basket total table
      to_order: 'للطلب',
      available_for_order: 'متاح للطلب',
      pay_for_order: 'الدفع للطلب',
      items: 'القطع',

      // Captcha
      captcha_field_placeholder: 'أدخل نصًا من الصورة أعلاه',

      //Catalog page
      offers_log_in:
        'To download offers, kindly login',
      offers_complete_registration:
        'To download offers, kindly deposit USD500 to complete registration',

      // Catalog table
      offer: 'عرض',
      download: 'تحميل',
      lines: 'خطوط',
      updated: 'تحديث',

      //Email verified page
      email_verified: 'Your email has been verified',

      // Log In dialog
      email: 'البريد الالكتروني',
      password: 'كلمة السر',
      forgot_password: 'هل نسيت كلمة السر?',
      reset_link_sent: 'تم ارسال رابط اعادة ضبط كلمة السر',
      log_in: 'تسجيل دخول',

      // Orders table
      date: 'التاريخ',
      order_price: 'سعر الطلب',
      sale_price: 'سعر البيع',
      ordered: 'تم الطلب',
      purchased: 'تم الشراء',
      shipped: 'تم الشحن',
      refused: 'تم الرفض',
      state_date: 'تاريخ الولاية',

      // Search input
      search_input_placeholder: 'بحث...',

      // Search table
      substituted: 'استبدل',
      days: 'الايام',
      available: 'متاح',
      volume_kg: 'الحجم (ك غ)',
      comment: 'تعليق',
      n_a: 'غير متوفر',

      // Shipment date dialog
      enabled: 'تمكين',

      // Shipment box table
      row_id: 'معرف الصف',

      // Shipment table
      place: 'الموقع',
      length_m: 'الطول (م)',
      height_m: 'العرض (م)',
      width_m: 'الوزن (م)',
      value_with_currency: '({{currency}}) القيمة',

      // Shipment total table
      places: 'المواقع',
      volume_m3: 'الحجم (م)',
      schedule: 'الجدول',
      choose_date: 'Choose date',

      // Sign up dialog
      company_name: 'اسم الشركة',
      contact_phone: 'رقم الهاتف',
      confirm_password: 'تأكيد كلمة السر',
      sign_up: 'التسجيل',
      error_password_not_match: 'Passwords do not match',

      //Manuals page
      tab_register: 'تسجيل',
      tab_quotations: 'لجعل الاقتباسات',
      tab_offers: 'لتنزيل العروض',
      tab_api: 'لاستخدام API',

      //TopUp Dialog
      error_amount: 'Incorrect amount',
      error_something: 'Something went wrong, contact us',
    },
  },
  ru: {
    translation: {
      //Page Tabs
      search: 'Поиск',
      cart: 'Корзина',
      orders: 'Заказы',
      dispatch: 'Отправка',
      balance: 'Баланс',
      login: 'Войти',
      register: 'Регистрация',
      logout: 'Выйти',
      offers: 'Прайсы',
      howto: 'Инструкции',

      //footer
      terms_and_conditions: 'Условия работы',
      contacts: 'Контакты',
      contact_us: 'Связаться',
      home: 'Home',
      about_us: 'О нас',
      privacy_policy: 'Политики',
      contact_number: '',
      follow_us_by: 'СоцСети',

      //header
      profile: 'ПРОФИЛЬ',

      // User data fields
      user_name: 'НАИМЕНОВАНИЕ',
      user_country: 'СТРАНА',
      user_zip: 'ИНДЕКС',
      user_state: 'ШТАТ/ПРОВИНЦИЯ',
      user_city: 'ГОРОД',
      user_street: 'УЛИЦА',
      user_building: 'ДОМ',
      user_flat: 'КВАРТИРА/ОФИС',
      user_phone: 'ТЕЛЕФОН',
      user_email: 'ЭЛЕКТРОННАЯ ПОЧТА',
      user_tax: 'НАЛОГОВЫЙ НОМЕР',
      user_currency: 'ВАЛЮТА',
      user_contact_person: 'КОНТАКТНОЕ ЛИЦО',
      save: 'СОХРАНИТЬ',

      // Search page
      by_part_number: 'Поиск по номеру',
      by_excel_file: 'Проценка файлом',
      to_cart: 'В корзину',
      export: 'Экспорт',
      upload: 'Загрузить',
      max_days: 'Не позднее',
      template: 'Шаблон',
      demo_prices_log_in:
        'Вы видите ДЕМО цены. Авторизуйтесь',
      demo_prices_complete_registration:
        'Вы видите ДЕМО цены. Внесите депозит USD500, чтобы завершить регистрацию',
      for_order: 'ЗАКАЗ',
      error_quotation: 'Ошибка выполнения котировки',
      quotation_differs:
        'По части позиций отличается цена, наличие или срок поставки',

      // Balance page
      cannot_export_invoice: 'Ошибка выгрузки документа',
      start_date: 'С',
      end_date: 'ПО',
      show: 'Показать',
      topup_online: 'Пополнить картой',
      bank_transfer: 'Банковский перевод',
      document: 'Документ',
      debit: 'Дебет',
      credit: 'Кредит',

      // Basket page
      cannot_select_item: 'Ошибка при выборе позиций в заказ',
      cannot_change_quantity: 'Ошибка изменения количества',
      cannot_apply_changes: 'Ошибка подтверждения изменений',
      cannot_order_selected: 'Ошибка размещения заказа',
      cannot_delete_selected: 'Ошибка при удалении',
      order: 'Заказ',
      remove: 'Убрать',
      order_accepted:
        'Ваш заказ № {{orderId}} в сумме {{currency}}{{amount}} принят.',
      order_not_accepted:
        'Ваш заказ не принят, код ошибки (code = {{orderId}})',
      activate_account: 'Свяжитесь с нами для активации аккаунта',
      insufficient_funds: 'Недостаточно средств на балансе',
      no_items: 'Не выбраны позиции в заказ',
      basket_log_in:
        'Для размещения заказов авторизуйтесь',
      basket_complete_registration:
        'Для размещения заказов внесите депозит USD500, чтобы завершить регистрацию',

      // Orders page
      part_number: 'Номер детали',
      order_number: 'Номер заказа',
      reference: 'Референс',
      all: 'Все',

      // Password reset page
      submit: 'Сменить',
      new_password: 'НОВЫЙ ПАРОЛЬ',

      // Shipments page
      cannot_update_shipment_date: 'Ошибка изменения даты отгрузки',

      // Balance topup dialog
      amount_with_currency: 'СУММА ({{currency}})',
      charge: 'КОМИССИЯ: {{percent}}% + {{fixed}} {{currency}}',
      topup: 'ПОПОЛНИТЬ',

      // Balance total table
      balance_with_currency: 'Баланс ({{currency}})',
      in_orders: 'В работе',
      in_cart: 'В корзине',
      pay_for_cart: 'Оплатить до корзины',
      ready_to_ship: 'К отгрузке',
      pay_to_ship: 'Оплатить к отгрузке',

      // Basket table
      brand: 'Марка',
      price_with_currency: 'Цена ({{currency}})',
      quantity: 'Количество',
      total_with_currency: 'Сумма ({{currency}})',
      weight_kg: 'Вес (кг)',
      booking: 'Букинг',
      delivery: 'Доставка',
      description: 'Описание',
      duplication: 'Дублирование',


      // Basket total table
      to_order: 'К заказу',
      available_for_order: 'Доступно к заказу',
      pay_for_order: 'Оплатить к заказу',
      items: 'Позиций',

      // Captcha
      captcha_field_placeholder: 'ВВЕДИТЕ СИМВОЛЫ НА КАРТИНКЕ',

      //Catalog page
      offers_log_in:
        'Для скачивания предложений авторизуйтесь',
      offers_complete_registration:
        'Для скачивания предложений внесите депозит USD500, чтобы завершить регистрацию',

      // Catalog table
      offer: 'Предложение',
      download: 'Скачать',
      lines: 'Строк',
      updated: 'Обновлено',

      //Email verified page
      email_verified: 'Почтовый адрес подтвержден',

      // Log In dialog
      email: 'email',
      password: 'пароль',
      forgot_password: 'Забыли пароль?',
      reset_link_sent: 'Ссылка для сброса пароля отправлена вам на почту',
      log_in: 'ВОЙТИ',

      // Orders table
      date: 'Дата',
      order_price: 'Цена заказа',
      sale_price: 'Цена продажи',
      ordered: 'В работе',
      purchased: 'Закуплено',
      shipped: 'Отгружено',
      refused: 'Отказано',
      state_date: 'Дата состояния',

      // Search input
      search_input_placeholder: 'Поиск...',

      // Search table
      substituted: 'Замена',
      days: 'ETA',
      available: 'Наличие',
      volume_kg: 'Объем (кг)',
      comment: 'Комментарий',
      n_a: 'НЕТ',

      // Shipment date dialog
      enabled: 'Активно',

      // Shipment box table
      row_id: 'Row ID',

      // Shipment table
      place: 'Грузоместо',
      length_m: 'Длина (м)',
      height_m: 'Высота (м)',
      width_m: 'Ширина (м)',
      value_with_currency: 'Сумма ({{currency}})',

      // Shipment total table
      places: 'Грузомест',
      volume_m3: 'Объем (㎥)',
      schedule: 'Дата отгрузки',
      choose_date: 'Выберите дату',

      // Sign up dialog
      company_name: 'Наименование компании',
      contact_phone: 'Контактный телефон',
      confirm_password: 'Подтвердите пароль',
      sign_up: 'Регистрация',
      error_password_not_match: 'Пароли не совпадают',

      //Manuals page
      tab_register: 'Зарегистрироваться',
      tab_quotations: 'Проценить',
      tab_offers: 'Скачать прайсы',
      tab_api: 'Подключиться к API',

      //TopUp Dialog
      error_amount: 'Неверная сумма',
      error_something: 'Что-то пошло не так, свяжитесь с нами',
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('languageCode')
      ? localStorage.getItem('languageCode')
      : 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
